
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace, Action } from 'vuex-class';

  import MessageBoxModal from '@/components/modals/message-box-modal/MessageBoxModal.vue';
  import eventBus from '@/utils/EventBus';
  import AscxLogin from '@rdss-mono/ascx-login';
  import AscxMfaEntry from '@rdss-mono/ascx-mfa-entry';
  import AscxSetPassword from '@rdss-mono/ascx-set-password';
  import AscxForgotPasswordEmail from '@rdss-mono/ascx-forgot-password-email';

  import { getErrorMessage, passwordValidation } from '@/utils/Helpers';

  const authModule = namespace('auth');

  @Component({
    components: {
      AscxLogin,
      MessageBoxModal,
      AscxMfaEntry,
      AscxSetPassword,
      AscxForgotPasswordEmail
    }
  })
  export default class AuthView extends Vue {
    loginStep = 'login-form';
    loginData = null;
    forgottenPasswordEmail = null;
    resettingPassword = false;
    isRequesting = false;
    error = null;
    loginError = null;
    setPasswordLength = 12;
    apiPasswordError = '';
    apiMfaError = '';

    private resetPasswordModalId = 'resetPasswordLogout';

    steps = {
      SMS_MFA: 'mfa-challenge',
      NEW_PASSWORD_REQUIRED: 'set-password'
    };

    @Action confirmResetPassword;
    @Action resetPassword;

    @authModule.Action login;
    @authModule.Action mfaChallenge;
    @authModule.Action newPasswordChallenge;

    /**
     * Checks for the credentials of the user depending on the response of the components.
     * If the credentials are correct it renders the next step of the log in process.
     * @param payload : { success: boolean; auth_challenge_session_id: string; auth_challenge_name: string; username: string; }
     * @returns void
     */
    private isSuccessfulCredentials(payload) {
      const {
        success,
        auth_challenge_session_id,
        auth_challenge_name,
        username
      } = payload;

      if (success) {
        this.error = null;
        this.apiPasswordError = '';
        this.apiMfaError = '';
        this.loginStep = this.steps[auth_challenge_name];
        this.loginData = {
          auth_challenge_session_id,
          auth_challenge_name,
          username
        };
        if (auth_challenge_name === 'SMS_MFA') {
          this.setFocus('#mfaCode');
        }
      } else {
        this.loginStep = 'login-form';
        this.loginData = null;
      }
    }

    private resetPasswordSuccess() {
      if (this.resettingPassword) {
        this.$bvModal.show(this.resetPasswordModalId);
      } else {
        this.loginStep = 'reset-success';
      }
    }

    private closeForgotPassword() {
      this.loginStep = 'login-form';
      this.apiPasswordError = '';
      this.apiMfaError = '';
      this.loginData = null;
      this.$bvModal.hide(this.resetPasswordModalId);
    }
    private get resetPasswordLogoutMessage() {
      return {
        status: `${this.$t('label.success')}`,
        message: `${this.$t('reset_password.logout.message')}`,
        buttonText: `${this.$t('button.logout_now')}`
      };
    }

    private updateResetPassword({ email }: { email: string }): void {
      this.resettingPassword = true;
      this.updateForgottenEmail({ email });
    }
    private updateForgottenEmail({ email }: { email: string }): void {
      this.forgottenPasswordEmail = email;
      this.loginStep = 'forgot-password-set';
    }

    private cancelLogin(): void {
      this.loginStep = null;
      this.loginData = null;
      this.apiPasswordError = '';
      this.apiMfaError = '';
      this.error = null;
      this.loginError = null;
      this.loginStep = 'login-form';
    }

    private cancelForgotPassword(): void {
      this.forgottenPasswordEmail = null;
      this.cancelLogin();
    }

    // Sets a timer to check if the UI should refresh the token
    // function safe to delete when session management gets implemented
    private successfulLogin() {
      eventBus.$emit('setTokenTimer');
    }

    mounted(): void {
      this.$store.dispatch('resetStore');
    }

    private translate(key): string {
      return `${this.$t(key)}`;
    }

    private loginAction({ email, password }) {
      if (email == '' && password == '') return;
      this.isRequesting = true;
      (async () => {
        try {
          const { data } = await this.login({ email, password });
          const { auth_challenge_session_id, auth_challenge_name } = data;
          // check if USER has MFA enabled
          if (auth_challenge_name) {
            this.isSuccessfulCredentials({
              success: true,
              auth_challenge_session_id,
              auth_challenge_name,
              username: email
            });
            this.isRequesting = false;
          } else {
            this.goToDashboard(email, data);
          }
        } catch (err) {
          if (!err.response.data) {
            err.response.data = {};
          }
          if (
            err.response.data.status_msg_key ===
            'rest_api.response.error.COGNITO_PASSWORD_RESET_REQUIRED_EXCEPTION'
          ) {
            this.updateResetPassword({ email });
            this.isRequesting = false;
          } else {
            this.error = `${this.$t(getErrorMessage(err))}`;
            this.isSuccessfulCredentials({ success: false });
            this.isRequesting = false;
          }
        }
      })();
    }

    private checkMfaChallenge({ mfaCode }) {
      if (this.error != null) {
        return;
      }
      this.isRequesting = true;
      const {
        auth_challenge_name: challengeName,
        auth_challenge_session_id: sessionId,
        username
      } = this.loginData;
      (async () => {
        try {
          const { data } = await this.mfaChallenge({
            mfaCode,
            challengeName,
            sessionId,
            username
          });
          this.goToDashboard(this.loginData.username, data);
        } catch (error) {
          this.error = `${this.$t(getErrorMessage(error))}`;
          this.isRequesting = false;
        }
      })();
    }

    private validatePassword(
      password: string,
      confirmPassword: string,
      passwordLenght: number
    ) {
      return passwordValidation(password, confirmPassword, passwordLenght);
    }

    private submitPassword(password) {
      this.isRequesting = true;
      (async () => {
        try {
          const { auth_challenge_name, auth_challenge_session_id, username } =
            this.loginData;
          const { data } = await this.newPasswordChallenge({
            challengeName: auth_challenge_name,
            sessionId: auth_challenge_session_id,
            username,
            password
          });

          this.isSuccessfulCredentials({
            success: true,
            auth_challenge_session_id: data.auth_challenge_session_id,
            auth_challenge_name: data.auth_challenge_name,
            username
          });
        } catch (error) {
          const message = getErrorMessage(error);
          this.apiPasswordError = message;
        } finally {
          this.isRequesting = false;
        }
      })();
    }

    private submitNewPassword({ password, mfaCode }) {
      this.isRequesting = true;
      (async () => {
        try {
          await this.confirmResetPassword({
            email: this.forgottenPasswordEmail,
            mfaCode,
            newPassword: password
          });
          this.resetPasswordSuccess();
        } catch (error) {
          const message = getErrorMessage(error);
          if (`${message}`.includes('PASSWORD')) {
            this.apiPasswordError = message;
          } else {
            this.apiMfaError = message;
          }
        } finally {
          this.isRequesting = false;
        }
      })();
    }

    private submitForgotPasswordEmail(email): void {
      (async () => {
        try {
          this.isRequesting = true;
          await this.resetPassword({ email });
          this.updateForgottenEmail({ email });
        } catch (loginError) {
          if (
            getErrorMessage(loginError) !==
            'rest_api.response.error.USER_ACCOUNT_NOT_FOUND'
          ) {
            this.loginError = getErrorMessage(loginError);
          } else {
            this.loginError =
              'rest_api.response.error.forgot_password.USER_ACCOUNT_NOT_FOUND';
          }
        } finally {
          this.isRequesting = false;
        }
      })();
    }

    private async setFocus(inputSelector: string): Promise<void> {
      await this.$nextTick();
      const input: HTMLInputElement = document.querySelector(inputSelector);
      input.focus();
    }

    private goToDashboard(username, data) {
      this.$store.dispatch('loadUserData', {
        ...data.user,
        accepted_tos: data.accepted_tos
      });
      const { accepted_tos } = data;
      // TODO: Remove localStorage when the session management is sorted out
      localStorage.setItem(
        'acme-session',
        JSON.stringify({
          username,
          id_token: data.id_token,
          access_token: data.access_token,
          user: { ...data.user, accepted_tos }
        })
      );
      // TODO: Remove localStorage when the session management is sorted out
      localStorage.setItem(
        'acme-refresh-token',
        JSON.stringify({ refresh_token: data.refresh_token })
      );
      this.$store.commit('updateAuth', true);
      this.successfulLogin();
      if (accepted_tos) {
        this.$router.push({ name: 'Home' });
        // Line safe to delete when session management gets implemented
        return;
      }
      this.$router.push({
        name: 'TermsOfService'
      });

      this.isRequesting = false;
    }
  }
